<template>
  <div>
    <rgaadd-tour v-if="$store.state.tour" />
    <section class="py-5 bg9 bs-shadow mobile-top">
      <div data-v-5a90ec03 class="container">
        <h1 class="text-white mb0 lh1">Returned Goods Authorization</h1>
        <h5 class="text-white lh1 mb0">Order #: {{ orderId }}</h5>
      </div>
    </section>
    <div class="container">
      <div class="d-flex number-showing mt20">
        <router-link
          :to="{ path: $store.state.route.from.fullPath }"
          class="mb0 lh1 mrauto list-btn dib d-flex center"
        >
          <i class="material-icons">keyboard_arrow_left</i>
          Back
        </router-link>
      </div>
      <div
        class="policy card-block my-3 bs-shadow"
        style="margin-bottom: 20px !important"
      >
        <h5>
          <span class="teal"
            >At least one part is required to proceed with the returned goods
            authorization</span
          >
        </h5>
        <hr />
        <div class="row">
          <div class="col-xs-12 col-md-6 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Part #</label>
              <input
                id="RGAadd-step-1"
                type="text"
                class="form-control"
                aria-describedby="Part Number"
                placeholder="Enter Part Number"
                v-model="partNum"
                required
              />
            </div>

            <div class="form-group">
              <label for="exampleFormControlTextarea1">Quantity</label>
              <input
                id="RGAadd-step-2"
                type="number"
                class="form-control"
                aria-describedby="Quantity"
                placeholder="Enter Quantity"
                v-model="quantity"
                required
              />
            </div>
          </div>
          <div class="col-xs-12 col-md-6 col-12">
            <div class="form-group">
              <label for="exampleInputEmail1">Color</label>
              <input
                id="RGAadd-step-3"
                type="text"
                class="form-control"
                aria-describedby="Color"
                placeholder="Enter Color"
                v-model="color"
                required
              />
            </div>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Reason</label>
              <input
                id="RGAadd-step-4"
                type="text"
                class="form-control"
                aria-describedby="Enter a reason"
                placeholder="Enter a reason"
                v-model="reason"
                required
              />

              <!--
              <small class="text-muted"
                >Need help deciding on a reason?
                <span
                  @click="openLightbox()"
                  style="cursor: pointer"
                  class="teal"
                  >Show me the reason definitions</span
                ></small
              >
              -->
            </div>
          </div>
          <div class="col-xs-12 col-md-12 col-12">
            <div id="RGAadd-step-5" class="form-group">
              <label for="exampleFormControlTextarea1" style="opacity: 0"
                >Add part
              </label>
              <div
                class="nav-btns listing-header d-flex align-items-center rounded b-shadow"
              >
                <button
                  v-if="
                    this.partNum.length &&
                    this.quantity.length &&
                    this.color.length &&
                    this.reason != ''
                  "
                  @click="submitTask"
                  class="mlauto"
                >
                  Submit Part <i class="material-icons">add</i>
                </button>
                <button v-else disabled @click="submitTask" class="mlauto">
                  Submit Part
                  <i class="material-icons">add</i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="todos.length" class="row">
          <div class="col col-12">
            <hr v-if="todos.length" />
            <!--
								<ul>
									<li v-for="(todo, index) in todos" v-bind:key="index">
										<div>
											
							<input type="checkbox" v-model="todo.completed" />
                            
											{{ todo.partNumber }}
										</div>
										<button v-on:click="remove(index)">delete</button>
									</li>
								</ul>
                -->
            <div class="table-responsive">
              <table
                class="table-striped table-bordered"
                style="display: table"
              >
                <thead>
                  <tr>
                    <th scope="col">Part #</th>
                    <th scope="col">Qty</th>
                    <th scope="col">Color</th>
                    <th scope="col">Reason</th>
                    <th scope="col"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    scope="row"
                    v-for="(todo, index) in todos"
                    v-bind:key="index"
                  >
                    <td>
                      <input
                        type="text"
                        v-model="todo.requestedPartNum"
                        style="padding: 10px; width: 100%"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        v-model="todo.requestedQuantity"
                        style="padding: 10px; width: 100%"
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        v-model="todo.requestedColor"
                        style="padding: 10px; width: 100%"
                      />
                    </td>
                    <td style="min-width: 200px">
                      <input
                        type="text"
                        class="form-control"
                        aria-describedby="Enter a reason"
                        placeholder="Enter a reason"
                        v-model="todo.reason"
                        required
                      />
                    </td>
                    <td style="text-align: center">
                      <button class="delete-btn" v-on:click="remove(index)">
                        <span class="material-icons"> delete </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <div
              class="nav-btns listing-header d-flex align-items-center mt10 mb10 rounded b-shadow"
            >
              <button v-if="this.todos.length < 1" disabled class="mlauto">
                Submit
                <i class="material-icons">keyboard_arrow_right</i>
              </button>
              <button v-else class="mlauto" @click="submitRGA()">
                Submit
                <i class="material-icons">keyboard_arrow_right</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- reason overlay -->
    <!-- problem is not in the header -->
    <transition name="menu-popover">
      <div v-if="reasonPreview" class="lightbox">
        <h2 class="closeX" @click="closeLightbox()">
          <a>&#215;</a>
        </h2>
        <div class="reason-wrap">
          <h3>SSC Part Return Definitions</h3>
          <hr />
          <h5>Defective Part:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Incorrect Part:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Freight Damaged:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Ordering More:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Damaged On Site:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Shortage:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Paint:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Fabrication:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Estimation:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Joint Sales:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>

          <h5>Project Admin:</h5>
          <p>sieuhw weodiwjedo qwedoiqejdoq</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import { AtomSpinner } from "epic-spinners";
import Avatar from "vue-avatar";
import rgaaddTour from "../components/Tours/rgaaddTour";

const auth = {
  headers: {
    Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
    //Authorization: "Bearer " + wontworkqwd2iwedjqoewiudwo
  },
};

export default {
  name: "RGAadd",
  components: {
    AtomSpinner,
    Avatar,
    rgaaddTour,
  },
  data() {
    return {
      ordermanageapi: process.env.VUE_APP_ORDER_MANAGE_API,
      server: process.env.VUE_APP_SERVER_URL,
      orderId: this.$route.params.id,
      userID: this.$store.state.user.UserId,
      returnedParts: [
        {
          partNum: "",
          quantity: 0,
          color: null,
          reason: "Select One",
          notes: "",
        },
      ],
      todos: [],
      partNum: "",
      quantity: "",
      color: "",
      reason: "",
      notes: "",
      reasons: [],
      returnedID: null,
      acid: null,
      reasonPreview: false,
    };
  },
  methods: {
    firstLoad: function () {
      //this.loading = true;
      this.$store.commit("setActivePage", "order-manage");
      return axios
        .get(`${this.ordermanageapi}/options?type=reason`, auth)
        .then((response, reasons) => {
          //console.log(response);
          this.reasons = response.data;
          this.loading = false;
          document
            .getElementById("mobileScrollTarget")
            .scrollIntoView({ behavior: "smooth" });
        });
    },
    submitTask() {
      this.todos.push({
        requestedPartNum: this.partNum,
        completed: false,
        requestedQuantity: this.quantity,
        requestedColor: this.color,
        reason: this.reason,
      });

      this.partNum = "";
      this.quantity = "";
      this.color = "";
      this.reason = "Select One";
      this.notes = "";
    },
    remove(index) {
      this.todos.splice(index, 1);
    },
    submitRGA() {
      var thePostDate = new Date();

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
        },
      };
      let postData = {
        orderId: this.$route.params.id,
        status: "Pending",
        disposition: "Pending Decision",
        requestedByUser: this.userID,
        submitDate: thePostDate,
        acid: this.acid,
        credit: "N/A",
        rgaParts: this.todos,
      };

      let sfData = {
        orderNum: this.$route.params.id,
        name: this.getName,
        emailAddress: this.getEmail,
      };

      axios
        .post(`${this.ordermanageapi}/rga`, postData, axiosConfig)
        .then(
          (response) => {
            //console.log(response);
            this.returnedID = response.data.rgaid;
            this.$noty.success("Successfully added RGA!");
          },
          (error) => {
            this.$noty.error("Sorry, there was an issue adding your RGA");
            //console.log(error);
          }
        )
        .then(() => {
          return axios
            .post(`${this.server}/api/v1/new-rga-email`, sfData, axiosConfig)
            .then((response) => {
              //console.log(response);
              this.$noty.success("Successfully created a new salesforce case!");
              this.$router.push(`/rga-details/${this.returnedID}`);
            })
            .catch((err) => {
              //console.log(err);
              this.$router.push(`/rga-details/${this.returnedID}`);
              this.$noty.error(
                "Sorry, there was an issue making a new salesforce case"
              );
            });
        });
    },
    openLightbox() {
      this.reasonPreview = true;
    },
    closeLightbox() {
      this.reasonPreview = false;
    },
  },
  computed: {
    activeNav() {
      return this.$store.state.activePage;
    },

    colorGetter() {
      return this.$store.state.colors;
    },

    getUsername() {
      return this.$store.state.user;
    },
    companyID() {
      let number = this.$store.state.user.ACId[0];
      this.acid = number;
    },
    getEmail() {
      return this.$store.state.user.Email;
    },
    getName() {
      return (
        this.$store.state.user.FirstName + " " + this.$store.state.user.LastName
      );
    },
  },
  created() {
    this.$store.commit("showNavigation");

    // console.log(this.$route.path);
  },
  mounted() {
    document
      .getElementById("mobileScrollTarget")
      .scrollIntoView({ behavior: "smooth" });
    this.firstLoad();
  },
};
</script>
<style scoped>
#pickDate1 input {
  border: 0 !important;
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #00b4b4;
  border-color: #00b4b4;
}

.leadTime {
  background-color: #fff;
  line-height: 44px;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}
.top-wrap {
  display: flex;
  align-items: flex-start;
}
.leadTime-bottom {
  align-items: flex-end;
}
.leadTime-header {
  background: #fff;
  color: #575757;
  display: flex !important;
}
.small-lh {
  line-height: 1.4em;
}

.listing {
  overflow: none;
}

.listing {
  background-color: #fff;
  line-height: 1;
  padding: 20px;
  margin-bottom: 5px;
  border-top: 0;
}

.table-responsive {
  display: table;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03) !important;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #e8e8e8 !important;
}
.badge-success {
  color: #fff;
  background-color: #4d5967;
  background-color: #1eb5b6;
}
.table-striped a {
  color: #00b4b4;
}
.table-striped td,
.table-striped th {
  padding: 5px;
  font-weight: 300 !important;
}
.listing {
  border-radius: 4px;
}
table td {
  line-height: 1;
}
.table-striped th {
  background: #00b4b4;
  background: #4d5967;
  color: #fff;
}
.table-striped {
  min-width: 100%;
  overflow: scroll;
}
.search-btns {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.search-btns button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: #4d5967;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  max-height: 40px;
}
.search-btns .clear {
  margin-left: auto;
}
.search-btns .search {
  margin-left: 5px;
}
.search-btns button span {
  font-size: 20px;
  width: 20px;
  height: 20px;
  color: #fff;
}
.search-btns button:hover {
  background: #586575;
}
.search-btns button:active {
  background: #586575;
}
.search-btns button:disabled {
  opacity: 0.6;
  cursor: unset !important;
}
.search-btns button:disabled:hover {
  background: #4d5967;
}
.search-btns button:disabled:active {
  background: #4d5967;
}

@media all and (min-width: 768px) {
  .dates {
    display: flex;
    color: #757575 !important;
  }
}
.top-search {
  color: #757575;
}
select.form-control:not([size]):not([multiple]) {
  height: auto !important;
  padding: 10px !important;
  color: #757575;
}
.all-contractors {
  color: #757575 !important;
}
.header-divide {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.table-striped td:last-of-type {
  text-align: right;
}
.table-striped th:last-of-type {
  text-align: right;
}
.table-bordered td:first-of-type {
  border-right: none !important;
}
.table-bordered th:first-of-type {
  border-right: none !important;
}
.table-bordered td:last-of-type {
  border-left: none !important;
}
.table-bordered th:last-of-type {
  border-left: none !important;
}
.m50 {
  margin: 50px 0;
}
.nav-btns {
  display: flex;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}
.listing-header {
  background: #fff;
  color: #575757;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.loader {
  margin: 0 auto 20px;
  width: 100%;
  height: 25px;
  border-radius: 14px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #999;
  background: #ccc;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: inherit;
  width: 0%;
  border-radius: inherit;
  position: relative;
  overflow: hidden;
}

.progress-stripes {
  width: inherit;
  height: inherit;
  font-size: 180px;
  font-weight: bold;
  margin-top: -50px;
  letter-spacing: -14px;
}

.percentage {
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: bold;
  font-size: 16px;
}
/* PURPLE */
.teal .progress-bar {
  background: #00b4b4;
}
.teal .progress-stripes {
  color: #00b4b4;
}
.teal .percentage {
  color: #eee;
}
.first-step {
  width: 10%;
}
.second-step {
  width: 30%;
}
.third-step {
  width: 50%;
}
.fourth-step {
  width: 80%;
}
.fifth-step {
  width: 90%;
}
.teal {
  color: #00b4b4;
}
.lookup-wrapper {
  text-align: center;
}
.lookup-svg {
  width: 300px;
  height: auto;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-right: auto;
  margin: 0 auto;
}
.show-all-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  /* background: #519bb2; */
  background: rgb(124, 124, 129);

  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;
}
.show-all-btn:hover {
  background: #4d5967;
}

.custom-radio .custom-control-label::before {
  background-color: #fff;
  border: 1px solid #00c7c7;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #00c7c7;
  border: 1px solid #00c7c7;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #00c7c7;
  border-radius: 50%;
}

.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: none;
}

.custom-control-input:checked ~ .custom-control-label.green::before {
  background-color: #00c7c7;
}

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-radio .custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fff;
}
td input {
  border: none !important;
}
.table-striped td {
  background: #efefef;
}
td select {
  border: none;
}
.file-input {
  background: #00b4b4;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}
.file-input .material-icons {
  font-size: 2em;
}
.flex-btn {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
}
.text-success {
  color: #00c7c7 !important;
}
.edit-list {
  display: flex;
  padding: 5px 10px;
  background: #f5f5f5;
  margin-bottom: 5px;
  border-radius: 4px;
}
.edit-list .file-right {
  margin-left: auto;
}
.edit-list .file-left {
  margin-right: 20px;
  text-align: left;
}
.file-item button {
  border: none;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #db5440;
  color: #fff;
  cursor: pointer;
  border: none !important;
  outline: none !important;
}

.file-item button:hover {
  background: #cc4e3c;
}

.file-item button:active {
  background: #cc4e3c;
}
.file-item .material-icons {
  line-height: 1;
  font-size: 16px;
}
button:disabled {
  background: rgba(0, 0, 0, 0.2) !important;
  border: none !important;
}

.delete-btn {
  border-radius: 4px;
  background: #db5440;
  border: none;
  color: #fff;
  padding: 4px;
  cursor: pointer;
}

.delete-btn button:hover {
  background: #cc4e3c;
}
.delete-btn button:active {
  background: #cc4e3c;
}
.delete-btn span {
  font-size: 18px;
}
.reason-wrap {
  display: block;
  position: relative;
  margin-top: 70px;
  background: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  padding: 20px;
  text-align: left;
  overflow: scroll;
  max-height: 80%;
}
.showme {
  color: #429da8;
}
.showme:hover {
  color: #00b4b4;
}
.showme:active {
  color: #00b4b4;
}
.lightbox {
  z-index: 10004;
}
/*
.custom-radio .custom-control-label::before {
	background: #429da8;
}
.custom-control-input:checked
	~ .custom-control-label.red[data-v-2ddd92a5]::before {
	border: 1px solid #429da8;
	background-color: #fff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	border: 1px solid #429da8;
	background-color: #fff;
	background-color: #fff;
	border-radius: 50%;
}
*/
</style>